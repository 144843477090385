
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import NetworkContext from '../context/network-context';
import { useInvestorHpayBalance } from '../state/investor-info';
import { useStakedAmount, useStakingActions, useStakingApr, useStakingReward, useTotalStakedAmount } from '../state/staking';
import { Tokens } from '../web3/contracts/contracts';
import { useHpayExchangePrice } from '../web3/exchange';
import StakingInput from './stake-input';

function Staking() {
    const { connected, isBsc, pendingTransaction, executeTransaction } = useContext(NetworkContext);
    const [showStakingModal, setShowStakingModal] = useState(false);
    const handleClose = () => {
        if (!pendingTransaction) {
            setShowStakingModal(false);
        }
    };
    const [modalStatus, setModalStatus] = useState(null);
    const { hpayBalance } = useInvestorHpayBalance();
    const { pendingReward } = useStakingReward();
    const { stakedAmount } = useStakedAmount();
    const { apr, apy } = useStakingApr();
    const { totalStakedAmount } = useTotalStakedAmount();
    const { harvestReward, stakeTokens, unstakeTokens, compoundReward } = useStakingActions();

    const [tlv, setTlv] = useState(0);
    const fetchRates = useHpayExchangePrice();

    useEffect(() => {
        const run = async () => {
            const price = await fetchRates(Tokens.HPAY, Tokens.BUSD);
            setTlv(price * totalStakedAmount);
        };
        run();
    }, [totalStakedAmount, setTlv, fetchRates]);

    const handleCompound = useCallback(async () => {
        const tx = async () => await compoundReward();
        await executeTransaction({
            message: 'Compounding',
            tx
        });
    }, [executeTransaction, compoundReward]);

    const handleClaim = useCallback(async () => {
        const tx = async () => await harvestReward();
        await executeTransaction({
            message: 'Harvesting',
            tx
        });
    }, [executeTransaction, harvestReward]);


    const handleStake = useCallback(() => {
        setModalStatus({
            mode: 'stake',
            balance: hpayBalance,
            action: async (amount) => {
                const tx = async () => await stakeTokens(amount);
                try {
                    await executeTransaction({
                        message: 'Staking',
                        tx
                    });
                } catch (error) {
                    console.log(error);
                }

                setShowStakingModal(false);
            }
        });

        setShowStakingModal(true);
    }, [setModalStatus, setShowStakingModal, executeTransaction, stakeTokens, hpayBalance]);

    const handleUnstake = useCallback(() => {
        setModalStatus({
            mode: 'unstake',
            balance: stakedAmount,
            action: async (amount) => {
                const tx = async () => {
                    return await unstakeTokens(amount);
                };
                try {
                    await executeTransaction({
                        message: 'Withdrawing',
                        tx
                    });
                } catch (error) {
                    console.log(error);
                }

                setShowStakingModal(false);
            }
        });

        setShowStakingModal(true);
    }, [setModalStatus, setShowStakingModal, unstakeTokens, executeTransaction, stakedAmount]);

    return (
        <>
            <div className="staking-component h-100">
                <Card>
                    <Card.Body>
                        <div style={{ marginBottom: '2.2rem' }}>
                            <Row noGutters>
                                <Col md={6} className="farming-stats-group text-lg-left text-center">
                                    <h3 className="mb-0 text-sm-left text-lg-left text-md-center text-center">HPay Staking</h3>
                                    <small>Total locked value <span className="text-primary-gradient" > $ <CountUp
                                        isCounting end={tlv || 0}
                                        decimals={2} preserveValue={true} separator=',' />
                                    </span> </small>
                                </Col>

                                <Col md={6} className="farming-stats-group text-lg-right text-center">
                                    <small className="farming-stats-value-label">APY </small>
                                    <p className="farming-stats-value-item text-primary-gradient">
                                        <CountUp isCounting end={apy || 0} decimals={2} preserveValue={true} separator=',' /> %
                                    </p>
                                </Col>
                            </Row>

                        </div>
                        <div>
                            <Row noGutters>
                                <Col sm={4} md={12} lg={7} className="farming-stats-group text-sm-right text-lg-right text-md-center text-center order-md-0 order-sm-2 order-lg-2">
                                    <Button onClick={handleClaim} disabled={!connected || !isBsc || !!pendingTransaction || pendingReward === 0}
                                        className="text-white mr-2">Claim </Button>

                                    <Button onClick={handleCompound} disabled={!connected || !isBsc || !!pendingTransaction || pendingReward === 0}
                                        className="text-white">Compound </Button>
                                </Col>

                                <Col sm={8} md={12} lg={5} className="farming-stats-group text-left">
                                    <small className="farming-stats-value-label">You Earned</small>
                                    <p className="farming-stats-value-item mb-0">
                                        <CountUp isCounting end={pendingReward || 0} decimals={5} preserveValue={true} separator=',' /> HPAY
                                    </p>
                                    {/* <span><span style={{opacity: '0.5'}}>out of total of</span> <strong>21,000,000.0100100909</strong> <strong className="text-primary-gradient">HPAY</strong></span> */}
                                </Col>
                            </Row>

                            <Row noGutters>
                                <Col className="farming-stats-group text-left">
                                    <small className="farming-stats-value-label">You Staked</small>
                                    <p className="farming-stats-value-item mb-0">
                                        <CountUp isCounting end={stakedAmount || 0} decimals={2} preserveValue={true} separator=',' /> HPAY
                                    </p>
                                </Col>

                                <Col className="farming-stats-group text-right">
                                    <small className="farming-stats-value-label">APR</small>
                                    <p className="farming-stats-value-item">
                                        {apr >= 20000 && <span>+</span>}  <CountUp isCounting end={apr || 0} decimals={2} preserveValue={true} separator=',' /> %
                                    </p>
                                </Col>
                            </Row>

                            <Row className="justify-content-between align-items-center mt-4">
                                <Col md={12} className='text-center mb-2 mt-2'>
                                    <small> * A 2% entry and exit fee will be deducted. </small>
                                </Col>
                                <Col sm={6} className="mb-sm-0 mb-3">
                                    <Button onClick={handleStake}
                                        disabled={!connected || !isBsc || !!pendingTransaction}
                                        className="text-white" block>Stake</Button>
                                </Col>
                                <Col sm={6} >
                                    <Button onClick={handleUnstake}
                                        disabled={!connected || !isBsc || !!pendingTransaction}
                                        className="text-white" block>Unstake</Button>
                                </Col>

                            </Row>


                        </div>

                        <Col lg={12} className="d-none">

                            <Row noGutters className="staking-stats-group">
                                <Col >
                                    <small className="farming-stats-value-label">Total Staked</small>
                                    <p className="farming-stats-value-item">
                                        <CountUp isCounting end={totalStakedAmount || 0} decimals={2} preserveValue={true} separator=',' /> HPAY
                                    </p>
                                </Col>

                            </Row>
                        </Col>

                    </Card.Body>
                </Card >
            </div>


            <Modal
                className="stake-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={!!showStakingModal} onHide={handleClose} >

                <Modal.Body>
                    <StakingInput  {...modalStatus}></StakingInput>
                </Modal.Body>

            </Modal>
        </>
    );
}

export default Staking;
