
import React, { useCallback, useContext, useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Countdown from 'react-countdown';
import CountUp from 'react-countup';
import NetworkContext from '../context/network-context';
import { useBountyActions, useBountyBonus, useBountyValue, useRefreshBountyValue } from '../state/bounty';
import { useInvestorHpayBalance } from '../state/investor-info';


const Bonus = ({ bonus, claimed }) => {
    const [ctEnded, setCtEnded] = useState(false);
    return (<small>
        {
            bonus.available || ctEnded ? <>Bonus + <CountUp isCounting end={bonus.amount || 0} decimals={2} preserveValue={true} separator=',' /> HPAY</> :
                <>
                    Next bonus in <Countdown onComplete={() => {
                        setCtEnded(true);
                    }} key={(bonus && bonus.nextBonus)} date={(bonus && bonus.nextBonus)}>
                    </Countdown>
                </>
        }

    </small>
    );
};

function BountyClaim() {
    const { hpayBalance } = useInvestorHpayBalance();
    const { claimBounty } = useBountyActions();
    const { bountyValue } = useBountyValue();
    const { bonus } = useBountyBonus();

    const [, refreshBounty] = useRefreshBountyValue();

    const { account, executeTransaction, connected, isBsc, pendingTransaction } = useContext(NetworkContext);

    useEffect(() => {
        const interval = setInterval(() => {
            refreshBounty();
        }, 2000);
        return () => {
            clearInterval(interval);
        };
    }, [refreshBounty]);

    const handleClaim = useCallback(async () => {
        const tx = async () => await claimBounty(account);

        await executeTransaction({
            message: 'Claiming Bounty ',
            tx
        });
    }, [account, claimBounty, executeTransaction]);

    return (
        <div className="reward-claim-component">
            <Row className="mb-lg-5 mb-4 reward-header">
                <Col md={8} lg={9}>
                    <h2 className="title-seperator">HedgePay Rewards</h2>
                    <p>
                        Claim at least 800k HedgePay to unlock your salary<sup>1</sup>. Only a select few will be granted access to this feature. <br />
                        Lower entry reward mechanics are currently being designed to be incrementally implemented to our platform.
                    </p>
                </Col>
                <Col md={4} lg={3} className="text-center mt-md-0 mt-5 text-md-right bounty-widget">
                    <div  className="mb-3">
                        <small className="farming-stats-value-label">Current Bounty</small>
                        <p className="farming-stats-value-item text-primary-gradient mb-0">
                            $ <CountUp isCounting end={bountyValue} decimals={8} preserveValue={true} separator=',' />
                        </p>
                        {bonus && <Bonus bonus={bonus}></Bonus>}
                    </div>
                    {hpayBalance > 1 ? <Button onClick={handleClaim} disabled={!connected || !isBsc || !!pendingTransaction || hpayBalance === 0}
                        className="btn text-white">Claim bounty</Button> : <p>
                        Hold a minimun of 1 HPAY in your wallet to qualifiy for bounty rewards.
                    </p>}
                </Col>
            </Row>
        </div>
    );
}

export default BountyClaim;
