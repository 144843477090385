import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import BountyClaim from '../components/bounty-claim';
import RewardInfo from '../components/reward-claim';
import Staking from '../components/staking';

const useIsClient = () => {
    const [isClient, setClient] = useState(false);
    const key = isClient ? "client" : "server";

    useEffect(() => {
        setClient(true);
    }, []);

    return { isClient, key };
};

function Rewards() {
    const { isClient } = useIsClient();

    if (!isClient) { return null; }
    return (
      
            <Row className="mb-2 ">
                <Col md={12} className="mb-md-5 mb-4">
                    <BountyClaim></BountyClaim>
                </Col>

                <Col md={7} className="mb-4">
                    <Staking></Staking>
                </Col>

                <Col md={5} className="mb-4">
                    <RewardInfo></RewardInfo>
                </Col>

            </Row>
     
    );
}

export default Rewards;
