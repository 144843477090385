import AutoNumeric from 'autonumeric';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import NetworkContext from '../context/network-context';
import { useApprove } from '../web3/account';
import { ContractAddresses } from '../web3/contracts/contracts';
import BigNumber from 'bignumber.js';


function StakingInput({ mode, balance, action }) {

    const { account, connected, isBsc, pendingTransaction, executeTransaction } = useContext(NetworkContext);
    const { approve, isApproved } = useApprove(ContractAddresses.HPAY, account, ContractAddresses.STAKING);
    const [isValid, setIsValid] = useState(false);
    const input = useRef(null);
    const [inputHandle, setInputHandle] = useState();

    const checkValid = useCallback(() => {
        if (inputHandle.getNumber() > balance) {

            setIsValid(false);
            return;
        }
        setIsValid(!!inputHandle.getNumber() && inputHandle.getNumber() > 0);
    }, [inputHandle, setIsValid, balance]);

    const handleApprove = useCallback(async () => {
        const tx = async () => await approve();

        await executeTransaction({
            message: 'Approving ',
            tx
        });

    }, [approve, executeTransaction]);

    useEffect(() => {
        setInputHandle(new AutoNumeric(input.current, 0, {
            decimalPlaces: 8,
            minimumValue: 0,
            decimalPlacesRawValue: 18,
            allowDecimalPadding: false,
            emptyInputBehavior: 0,
            decimalCharacterAlternative: ','
        }));

    }, [input, setInputHandle]);

    const setInput = useCallback((value) => () => {
        let nr = new BigNumber(+((balance * value) / 100)).toFixed(6, 1);
        if (value === 100) {
            nr = balance;
        }
        inputHandle.set(+nr);
        checkValid();
    }, [inputHandle, checkValid, balance]);

    const ActionButton = () => {
        if (isApproved || mode !== 'stake') {
            return (<Button disabled={!connected || !isValid || !isBsc || !!pendingTransaction}
                onClick={() => {
                    const amount = inputHandle.getNumber() === balance && mode !== 'stake' ? 0 : new BigNumber(inputHandle.getNumber()).toFixed(6, 1);
                    action(amount);
                }}
                block>{
                    !pendingTransaction ? mode === "stake" ? "Deposit" : "Withdraw" : <>

                        Processing...

                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />

                    </>}
            </Button>);
        } else {
            return (<Button disabled={!connected || !isBsc || !!pendingTransaction}
                onClick={handleApprove} block>Approve</Button>);
        }
    };
    return (
        <div className="staking-input-component">
            <Card>
                <Card.Body>
                    <Row style={{ marginBottom: '3.5rem' }}>
                        <Col sm={12} md={6} className=" text-md-left text-center  mb-1 ">
                            <h3 className="mb-0 text-white text-center text-md-left">{mode === 'stake' ? 'Stake' : 'Unstake'}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form>
                                <Form.Row className="justify-content-center no-gutters">

                                    <Form.Group as={Col} md="12" className="staking-control" controlId="validationFormik01">
                                        <div className="d-flex justify-content-between">
                                            <Form.Label className="balance-label">Amount</Form.Label>
                                            <Form.Label className="text-right balance-label">
                                                Balance <NumberFormat decimalScale={6} value={+balance.toFixed(4)} displayType={'text'} thousandSeparator={true} />
                                            </Form.Label>
                                        </div>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                className="staking-input mb-2"
                                                type="text"
                                                autoComplete="off"
                                                inputMode="decimal"
                                                name="baseInput"
                                                ref={input}
                                                onChange={checkValid}
                                            />
                                            <InputGroup.Append className="staking-input-append">
                                                HPAY
                                            </InputGroup.Append>
                                        </InputGroup>
                                        <div className="flex justify-content-between">
                                            <span role="button" tabIndex="0" onKeyDown={setInput(25)} onClick={setInput(25)} className="clickable badge badge-pill badge-primary-gradient">25%</span>
                                            <span role="button" tabIndex="0" onKeyDown={setInput(50)} onClick={setInput(50)} className="clickable badge badge-pill badge-primary-gradient">50%</span>
                                            <span role="button" tabIndex="0" onKeyDown={setInput(75)} onClick={setInput(75)} className="clickable badge badge-pill badge-primary-gradient">75%</span>
                                            <span role="button" tabIndex="0" onKeyDown={setInput(100)} onClick={setInput(100)} className="clickable badge badge-pill badge-primary-gradient">100%</span>
                                        </div>

                                    </Form.Group>
                                    <Form.Group as={Col} md="12" className="mt-4">
                                        <ActionButton />
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Col>

                    </Row>
                </Card.Body>
            </Card >
        </div >
    );
}

export default StakingInput;
